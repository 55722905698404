<template>
  <div class="loading">
    <a-spin />
  </div>
</template>

<script>
import { isShopLine } from '../../utils/shopline'

export default {
  mounted() {
    // 创建 URLSearchParams 对象
    const params = new URLSearchParams(window.location.search)

    // 获取参数值
    const appkey = params.get('appkey') // "John"
    const handle = params.get('handle') // "30"
    const sign = params.get('sign') // "30"
    const scope = params.get('scope') // "30"
    const timestamp = params.get('timestamp') // "30"
    const code = params.get('code') // "30"
    console.log({
      appkey, handle, sign, timestamp, scope, code
    })

    if (appkey && handle && sign && timestamp) {
      isShopLine()
    }
  }
}
</script>

<style>
.loading{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
